<template>
	<div class="foot_box width-100">
		<div class="con_box flex">
			<ul class="flex flex_between width-100">
				<li class="flex flex_column seat li_height">
					<!-- <h5>积云教育总部</h5>
					<p>北京市昌平区TBD云集中心4号楼C座</p> -->
					<h5>北京校区</h5>
					<p>北京市海淀区上庄路白水洼77号</p>
					<h5>河南校区</h5>
					<p>郑州市航空港区航田智能手机产业园5号地</p>
					<h5>联系我们</h5>
					<p>400-800-2320 &emsp; yunyingzu@usian.cn</p>
				</li>
				<li class="flex  or_code li_height">
					<h5 class="gd-text">更多精彩内容请关注</h5>
					<div class="flex">
						<img src="../../assets/images/dyh_03.jpg" alt />
						<p>服务号</p>
					</div>

					<div class="flex"> 
						<img src="../../assets/images/fwh_03.jpg" alt />
						<p>订阅号</p>
					</div>
				</li>
			</ul>
		</div>

		<!-- 友情链接 -->
		<!-- <div class="links">
			<h5>友情链接</h5>
			<p>
				<a target="_blank" href="http://www.broaderwaysz.com/">龙岗英语培训</a>
				<a target="_blank" href="https://www.linshigongw.com/ ">成人中专招生网</a>
				<a target="_blank" href="https://www.chengkaoq.com/">襄阳成考网</a>
				<a target="_blank" href="https://www.compassedu.hk/awnewc">英国热门院校</a>
				<a target="_blank" href="http://www.krirkcn.com/home/index">泰国留学</a>
				<a target="_blank" href="http://hlj.zgsydw.com/">黑龙江事业单位招聘</a>
				<a target="_blank" href="https://us.yiyiarts.net/">美国艺术留学</a>
				<a target="_blank" href="http://www.mariocollege.com/">对外汉语教师资格证</a>
			</p>
		</div> -->

		<div class="statement">
			<p>关于我们：</p>
			<p>北京优思安科技有限公司，致力于打造中国创新创业职业教育实训基地，打造学员价值、提高企业价值、创造社会价值。为国家和全社会经济发展提供强有力的人才支撑。</p>
		</div>
		<div class="copyright">
			<p>
			  Copyright 2018 - 2024 Usian.cn 北京优思安科技有限公司
			  <br>
			  版权所有
			  <a href="https://beian.miit.gov.cn" target="_blank"
				>京ICP备14057463号-1</a
			  >
			</p>
		  </div>
	</div>
</template>
<script>
	const axios = require("axios").default;
	export default {
		data() {
			return {
				links: [], //友情链接
			};
		},
		created() {
			this.getLinks();
		},
		methods: {
			toTop() {
				// chrome
				document.body.scrollTop = 0;
				// firefox
				document.documentElement.scrollTop = 0;
				// safari
				// window.pageYOffset = 0;
			},
			getLinks() {
				axios
					.get("/api/block/getItemsByName?name=" + encodeURIComponent("友情链接"))
					.then((res) => {
						this.links = res.data.data;
						// console.log("友情链接", this.links);
					});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.nofollow {
		padding: 20px 0;
	}

	.width-100 {
		width: 100%;
	}

	.flex {
		display: flex;
		display: -webkit-flex;
	}

	.flex_column {
		flex-flow: column nowrap;
		align-items: flex-start;
	}

	.flex_row {
		flex-flow: row nowrap;
	}

	.flex_between {
		flex-flow: row nowrap;
		justify-content: space-between;
	}

	.flex_content {
		justify-content: start;
	}

	.font_style {
		font-size: 16px;
		font-family: PingFang;
		font-weight: 500;
		color: rgba(145, 146, 152, 1);
	}

	.border_box {
		width: 1px;
		height: 10.41vw;
		background: rgba(15, 16, 26, 1);
	}

	.foot_box {
		padding: 20px 0 100px 0;
		// margin-top: -100px;
		position: relative;
		background-color: rgb(28, 41, 72);
		background: #063651;
	}

	.con_box {
		width: 1200px;
		height: 100%;
		margin: 0 auto;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
	}

	h5 {
		font-size: 18px;
		line-height: 2.604vw;
		font-family: PingFang;
		font-weight: 500;
		color: rgba(255, 255, 255, 1);
	}

	.seat p,
	.support_link p {
		font-size: 16px;
		font-weight: 500;
		text-align: left;
		line-height: 1.875vw;
		color: rgba(145, 146, 152, 1);
	}

	.support_box {
		width: 14.0625vw;
		flex-flow: column nowrap;
	}

	.support_phone {
		width: 100%;
		align-items: center;
	}

	.support_phone p {
		font-size: 16px;
		font-weight: 400;
		line-height: 1.5629vw;
		color: rgba(166, 167, 173, 1);
	}

	.support_phone img {
		width: 1.041vw;
		height: 1.041vw;
		margin-right: 10px;
	}

	.or_code {
		width: 18.75vw;
	}

	.or_code div {
		width: 7.2916vw;
		height: 10vw;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: space-around;
	}

	.or_code div img {
		width: 100%;
		height: 7.2916vw;
	}

	.or_code p {
		font-size: 18px;
		font-weight: 500;
		color: rgba(145, 146, 152, 1);
	}

	.copyright {
		width: 100%;
		height: 3.125vw;
		font-size: 16px;
		font-weight: 500;
		margin-top: 10px;
		// background: rgba(9, 11, 25, 1);
		line-height: 2.125vw;
		position: absolute;
		/* left: 0;
		bottom: 0; */
	}

	.copyright p,
	.copyright a {
		// color: rgba(79, 79, 86, 1);
		color: #919298;
	}

	.copyright a {
		text-decoration: none;
	}

	.jiy {
		position: fixed;
		right: 4px;
		top: 35%;
		width: 68px;
		z-index: 9999999999;
	}

	.jiy img {
		vertical-align: middle;
	}

	.nofollow li {
		display: inline-block;
		margin: 0 1px 0 0;
	}

	.links {
		width: 1200px;
		margin: 0 auto;
		text-align: left;
	}

	.links a {
		font-size: 16px;
		font-weight: 500;
		line-height: 1.875vw;
		color: #919298;
		margin-right: 16px;
		text-decoration: none;
	}

	.statement {
		margin-top: 1rem; 
		font-size: 16px;
		font-weight: 500;
		line-height: 1.875vw;
		color: #919298; 
		text-decoration: none;
		text-align: left;
	}

	.statement p {
		width: 1200px;
		margin: 0 auto;
	}
	.statement p:nth-child(1){
		font-size: 18px;
		color: #fff;
	}
	.or_code >div{
		margin-top: 50px;
		margin-right: 20px;
	}
	.gd-text {
		position: absolute;
	}
</style>
